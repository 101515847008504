<template>
  <v-container fluid class="content-wrapper mb-4">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("RateAndInventory.rateplan") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>

    <div v-if="loadingData">
      <v-skeleton-loader type="heading" class="mb-5"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"></v-skeleton-loader>
    </div>

    <v-row v-else row wrap class="mb-3">
      <v-col cols="9">
        <div class="title-topic">{{ $t("RateAndInventory.rateplan_topic") }}</div>
      </v-col>
      <v-col cols="3">
        <div
          class="dropdown select"
          @click="getfocus('status')"
          @mouseleave="mouseOver('status')"
        >
          <a id="status">
            <v-select
              v-model="search_status"
              :items="itemsStatus"
              item-text="value"
              item-value="_id"
              label
              :placeholder="$t('SignupStep.select_one')"
              :no-data-text="$t('SignupStep.nodata')"
              color="pink"
              solo
              flat
              dense
              single-line
              hide-details
            >
              <template v-slot:item="data">
                <v-list-item-content
                  @mouseenter="getfocus('status')"
                  @mouseleave="mouseOver('status')"
                >
                  <v-list-item-title
                    v-html="data.item.value"
                    class="title-signup-sj"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:no-data>
                <v-btn @click="initStatus" text color="#1e9ff2" style="width: 100%">{{
                  $t("SignupStep.btn_refresh")
                }}</v-btn>
              </template>
            </v-select>
          </a>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card tile class="card-header pa-4">
          <v-data-table
            :headers="headersRatePlan"
            :items="itemsRatePlan"
            class="elevation-0"
            fixed-top
            fixed-header
            style="max-height: 80vh; overflow-y: auto"
            id="rateplanTable"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="_id"
            @click:row="clickRow"
            :no-data-text="$t('RateAndInventory.nodata')"
            :item-class="itemRowBackground"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <a
                  class="btn-add btn-info btn-min-width btn-glow"
                  @click="gotoLink('create', null)"
                  >{{ $t("RateAndInventory.new_rateplan") }}</a
                >
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="1" class="flex-column py-3">
                <span class="title-room-name">
                  {{ $t("RateAndInventory.roome_name") }}
                </span>
                <v-row v-for="ele in item.rooms" :key="ele._id">
                  <v-col cols="2" />
                  <v-col v-if="ele.room" cols="10" class="text-table-gray">{{
                    ele.room.value || $t("RateAndInventory.roome_name")
                  }}</v-col>
                  <v-col v-else cols="10" class="text-table-gray">{{
                    $t("RateAndInventory.roome_name")
                  }}</v-col>
                </v-row>
              </td>
              <td :colspan="1" class="py-3">
                <div class="title-room-name">
                  {{ $t("RateAndInventory.voucher_type") }}
                </div>
                <div class="title-room-name text-table-gray">
                  {{
                    item.food_service && item.package.length
                      ? $t("RateAndInventory.both_package_and_breakfast")
                      : item.food_service == "breakfast" && item.package.length == 0
                      ? $t("RateAndInventory.breakfast")
                      : item.food_service &&
                        item.food_service !== "breakfast" &&
                        item.package.length == 0
                      ? $t("RateAndInventory.include_food")
                      : !item.food_service && item.package.length
                      ? $t("RateAndInventory.package")
                      : $t("RateAndInventory.room_only")
                  }}
                </div>
              </td>
              <td :colspan="1" class="py-3">
                <div class="title-room-name">{{ $t("RateAndInventory.rest_less") }}</div>
                <div v-if="item.minimum_stay > 1" class="title-room-name text-table-gray">
                  {{ item.minimum_stay }} {{ $t("RateAndInventory.days") }}
                </div>
                <div v-else class="title-room-name text-table-gray">
                  {{ $t("RateAndInventory.no_under") }}
                </div>
              </td>
              <td :colspan="1" class="py-3">
                <div v-if="item.age_of_voucher" class="title-room-name text-table-gray">
                  <div
                    v-if="item.age_of_voucher.expire_date || item.age_of_voucher.length"
                    class="title-room-name"
                  >
                    {{ $t("RateAndInventory.voucher_exp") }}
                  </div>
                  <span v-if="item.age_of_voucher.expire_date">
                    {{ $t("RateAndInventory.can_live_to") }}
                    {{ moment(item.age_of_voucher.expire_date).format("YYYY/MM/DD") }}
                  </span>
                  <span v-else-if="item.age_of_voucher.length">
                    {{ $t("RateAndInventory.can_live_to") }}
                    {{
                      moment(item.createdAt)
                        .add(item.age_of_voucher.length, "days")
                        .format("YYYY/MM/DD")
                    }}
                  </span>
                </div>
              </td>
              <td :colspan="2" class="py-3">
                <v-row>
                  <v-col cols="6">
                    <div
                      class="btn-data-table btn-warning"
                      style="min-width: 5.5rem"
                      @click="gotoLink('edit', item)"
                    >
                      {{ $t("RateAndInventory.btn_edit") }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div
                      class="btn-data-table btn-danger"
                      style="min-width: 5.5rem"
                      @click="removeRatePlanFunc(item)"
                    >
                      {{ $t("RateAndInventory.btn_remove") }}
                    </div>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item.rate_plan="{ item }">
              <v-icon v-if="!checkRowExpanded(item)" small>mdi-chevron-right</v-icon>
              <v-icon v-else-if="checkRowExpanded(item)" small>mdi-chevron-down</v-icon>
              <span v-if="item.name || item.rate_plan" class="text-bold">
                {{ item.name || item.rate_plan.value }}
              </span>
              <span v-else class="text-bold">
                {{ $t("RateAndInventory.rateplan_name") }}
              </span>
            </template>
            <template v-slot:item.policy_change_date="{ item }">
              <span v-if="item.policy_change_date">
                {{ $t("RateAndInventory.can_modify_booking") }}
                {{ item.policy_change_date.free_before || 0 }}
                {{ $t("RateAndInventory.days") }}
              </span>
              <span v-else>
                {{ $t("RateAndInventory.add_rateplan_change_date3") }}
              </span>
            </template>
            <template v-slot:item.rooms="{ item }">
              <span>
                {{ $t("RateAndInventory.th_unit") }}
                {{
                  item.rate_plan_price
                    ? checkRatePlanPrice(item)
                    : item.rate_plan_price
                    ? priceFormatter(item.rate_plan_price)
                    : checkRatePlanPrice(item.rooms)
                }}
              </span>
            </template>
            <template v-slot:item.rate="{ item }">
              <span v-if="item.rate"
                >{{ priceFormatter(item.rate_plan_percen) || item.rate.enum || 0 }}%</span
              >
              <span v-else>0%</span>
            </template>
            <template v-slot:item.earn="{ item }">
              <span>
                {{ $t("RateAndInventory.th_unit") }}
                {{ priceFormatter(item.netPrice) || item.netPrice || 0 }}
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                :color="item.status == 'active' ? '#28d094' : '#6c757d'"
                dark
                class="d-flex justify-center align-center"
                style="min-width: 7rem"
                @click.stop.prevent="changeRatePlanStatusFunc(item)"
              >
                {{
                  item.status == "active"
                    ? $t("RateAndInventory.avaliable")
                    : $t("SigninPromotion.expired")
                }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import moment from "moment";

export default {
  name: "SigninRatePlanScreen",
  metaInfo: {
    title: "Rate Plan",
    titleTemplate: "%s | Aigent",
  },
  beforeRouteLeave(to, from, next) {
    EventBus.$emit("endLoadingFunc");
    next();
  },
  data: () => ({
    moment: moment,
    userToken: "",
    propertyID: "",
    value_element: "",
    itemsStatus: [],
    search_status: "",
    itemsDateRange: [],
    search_select_date: 0,
    search_start_date: "",
    search_end_date: "",
    itemsRatePlan: [],
    headersRatePlan: [],
    expanded: [],
    singleExpand: false,
    limit: 10,
    page: 1,
    showRowItem: {},
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  watch: {
    search_select_date() {
      const self = this;
      var addMonth = 0;
      // console.log("search_select_date: ", self.search_select_date);
      if (self.search_select_date == 0) addMonth = 1;
      else if (self.search_select_date == 1) addMonth = 3;
      else if (self.search_select_date == 2) addMonth = 6;
      else addMonth = 12;
      var currentDate = moment();
      var futureMonth = moment(currentDate).subtract(addMonth, "months");
      self.search_start_date = moment(futureMonth).format("YYYY-MM-DD");
      self.search_end_date = moment(currentDate).format("YYYY-MM-DD");
      self.initRatePlan();
    },
    search_status() {
      const self = this;
      self.initRatePlan();
    },
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-blue-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.add("border-blue-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-blue-focus");
        element.classList.remove("border-blue-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true;
      self.initStatus();
      self.itemsDateRange = [
        {
          _id: 0,
          value: self.$t("RateAndInventory.30days"),
        },
        {
          _id: 1,
          value: self.$t("RateAndInventory.3months"),
        },
        {
          _id: 2,
          value: self.$t("RateAndInventory.6months"),
        },
        {
          _id: 3,
          value: self.$t("RateAndInventory.12months"),
        },
      ];
      var currentDate = moment();
      var futureMonth = moment(currentDate).subtract(1, "months");
      self.search_start_date = moment(futureMonth).format("YYYY-MM-DD");
      self.search_end_date = moment(currentDate).format("YYYY-MM-DD");

      self.headersRatePlan = [
        {
          text: self.$t("RateAndInventory.rateplan_name"),
          align: "start",
          sortable: false,
          value: "rate_plan",
        },
        {
          text: self.$t("RateAndInventory.rateplan_modify"),
          align: "start",
          sortable: false,
          value: "policy_change_date",
        },
        {
          text: self.$t("RateAndInventory.rateplan_price"),
          align: "start",
          sortable: false,
          value: "rooms",
        },
        {
          text: self.$t("RateAndInventory.rateplan_rate"),
          align: "start",
          sortable: false,
          value: "rate",
        },
        {
          text: self.$t("RateAndInventory.rateplan_earn"),
          align: "start",
          sortable: false,
          value: "earn",
        },
        {
          text: self.$t("RateAndInventory.rateplan_status"),
          align: "start",
          sortable: false,
          value: "status",
        },
      ];
      self.initRatePlan();
      setTimeout(() => {
        self.loadingData = false;
      }, 1500);
    },
    async initStatus() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        // console.log("temp: ", temp);
        self.itemsStatus = temp?.length
          ? temp
          : [
              {
                _id: "",
                value: self.$t("RateAndInventory.all_status"),
              },
              {
                _id: "active",
                value: self.$t("RateAndInventory.avaliable"),
              },
              {
                _id: "inactive",
                value: self.$t("SigninPromotion.expired"),
              },
            ];
      }
    },
    refreshRatePlan() {
      const self = this;
      self.search_select_date = 0;
      var currentDate = moment();
      var futureMonth = moment(currentDate).subtract(1, "months");
      self.search_start_date = moment(futureMonth).format("YYYY-MM-DD");
      self.search_end_date = moment(currentDate).format("YYYY-MM-DD");
      self.initRatePlan();
    },
    async initRatePlan() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?property_id=${self.propertyID}${
              self.search_status && "&status=" + self.search_status
            }`,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            temp = res?.data?.result || [];
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.itemsRatePlan = temp;
        self.clickRow(temp[0], null);
        // console.log("rateplan: ", temp);
      }
    },
    clickRow(item, event) {
      this.showRowItem = {};
      if (event?.isExpanded) {
        const index = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(index, 1);
      } else {
        if (item?.rooms?.length) {
          this.expanded.push(item);
        }
      }
    },
    checkRowExpanded(item) {
      const self = this;
      var tmp = false;
      self.expanded?.map((el) => {
        if (el?._id == item._id) tmp = true;
      });
      return tmp;
    },
    itemRowBackground(item) {
      const self = this;
      // console.log(item)
      var tmp = "";
      self.expanded?.map((el) => {
        if (el?._id == item._id) tmp = "show-expanded-item-rateplan";
      });
      return tmp;
    },
    checkRatePlanPrice(item) {
      let initialValue = 0;
      // console.log(item);
      let sum = item?.rate_plan_price
        ? item?.rate_plan_price
        : item?.reduce((total, currentValue) => {
            return total + (currentValue.base_rate || currentValue.adult_rate);
          }, initialValue);

      // console.log("sum: ", sum, this.priceFormatter(sum));

      return sum ? this.priceFormatter(sum) : "0.00";
    },
    checkRatePlan(item) {
      let initialValue = 0;
      // console.log("sum: ", item);
      let sum = item?.rate_plan_price_all?.length
        ? item?.rate_plan_price_all?.reduce((total, currentValue) => {
            // console.log("currentValue: ", currentValue);
            return total + currentValue;
          }, initialValue)
        : item?.reduce((total, currentValue) => {
            // console.log("currentValue: ", currentValue);
            return total + (currentValue.base_rate || currentValue.adult_rate);
          }, initialValue);
      // console.log("sum: ", sum, this.priceFormatter(sum));

      return sum ? this.priceFormatter(sum) : "0.00";
    },
    priceFormatter(value) {
      return Number(value || 0)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    gotoLink(action, item) {
      const link = {
        name:
          action == "create"
            ? "SigninRatePlanCreateScreen"
            : action == "edit" && item.type == "custom"
            ? "SigninRatePlanUpdateFixScreen"
            : action == "edit" && item.type == "flexible"
            ? "SigninRatePlanUpdateFullScreen"
            : action == "edit" && !item.type
            ? "SigninRatePlanUpdateFixScreen"
            : null,
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
          rateplanId: item?._id || "",
        },
      };
      // const link = {
      //   name: "SignupFacilitiesScreen",
      //   params: {
      //     propertyId: self.propertyID,
      //     userToken: self.userToken,
      //     rateplanId: item?._id || "",
      //   },
      // };
      EventBus.$emit("changePathname", link);
    },
    removeRatePlanFunc(item) {
      const self = this;
      swal(self.$t("RateAndInventory.delete_rateplan"), {
        dangerMode: true,
        buttons: {
          cancel: self.$t("RateAndInventory.btn_no"),
          confirm: {
            text: self.$t("RateAndInventory.btn_yes"),
            value: "confirm",
          },
        },
      }).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.delete(
              process.env.VUE_APP_API +
                `/property/rate_plan_price?rate_plan_price_id=${item._id}`,
              {
                headers: {
                  Authorization:
                    self?.userToken ||
                    localStorage?.getItem("Token") ||
                    self?.$store?.state?.resSignUpData?.access_token,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res.status == 200) self.initRatePlan();
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            EventBus.$emit("endloading");
            self.initRatePlan();
          }
        } else {
          self.initRatePlan();
          swal(
            self.$t("Alert.warn_title"),
            self.$t("RateAndInventory.not_delete_rateplan_yet"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      });
    },
    changeRatePlanStatusFunc(item) {
      const self = this;
      const obj = {
        status: item.status == "active" ? "inactive" : "active",
      };
      swal(
        `${self.$t("RateAndInventory.changestatusto")} ${
          item.status == "active"
            ? self.$t("SigninPromotion.expired")
            : self.$t("RateAndInventory.avaliable")
        } ${self.$t("RateAndInventory.yesorno")}`,
        {
          buttons: {
            cancel: self.$t("RateAndInventory.btn_no"),
            confirm: {
              text: self.$t("RateAndInventory.btn_yes"),
              value: "confirm",
            },
          },
        }
      ).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.put(
              process.env.VUE_APP_API +
                `/property/rate_plan_price?rate_plan_price_id=${item._id}`,
              obj,
              {
                headers: {
                  Authorization:
                    self?.userToken ||
                    localStorage?.getItem("Token") ||
                    self?.$store?.state?.resSignUpData?.access_token,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
            if (res.status == 200) self.initRatePlan();
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            EventBus.$emit("endloading");
            self.initRatePlan();
          }
        } else {
          self.initRatePlan();
          // swal(
          //   self.$t("Alert.warn_title"),
          //   self.$t("RateAndInventory.not_delete_rateplan_yet"),
          //   self.$t("Alert.warn_label"),
          //   {
          //     button: false,
          //     timer: 3000,
          //   }
          // );
        }
      });
    },
  },
};
</script>

<style>
@import "../../../../styles/signin/rateplan.css";

.show-expanded-item-rateplan {
  background-color: #f8f7f7 !important;
}
</style>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/promotion.css";

#rateplanTable::-webkit-scrollbar {
  display: none;
}

#rateplanTable {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
</style>
